body {
    margin: 0px;
    background-color: #232323;
    cursor: default; /* Ensure default cursor for the body */
    user-select: none; 

  }
  
  .Header {
    background-color: #232323;
    /* box-shadow: 1px 1px 1px RGBA(0, 123, 255, 1); */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  
    /* sticky header */
    position: fixed;
    width: 100%;
    z-index: 1000;
    /* transition: top 4s ease-out; */
    transition: top 3s cubic-bezier(0.4, 0, 0.2, 1);
    text-decoration: none;
    display: flex;
    align-content: center;
    cursor: default; /* Ensure default cursor for the body */

  }
  
  .header--title {
    margin-left: 10px;
    text-decoration: none;
    flex-direction: row;

    display: flex; /* Use flexbox layout */
    align-items: center; /* Vertically center items */
    margin: auto;
  }
  
  .header--title > h1 {
    font-family: Karla, sans-serif;
    font-weight: 700;
    margin: 0px;
    font-size: 1.7rem;
    color: white;
    margin-left: 46px; /*offset the screen that the user img takes up */
  }

  .header--image {
    width: 1.7rem; /* Match the font size of the text */
    height: 1.5rem; /* Match the font size of the text */
    margin-left: 3px;
  }
  
  .header--user {
    background: transparent;
    opacity: 0.5;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 3px;
  }
  
  .header--user > img {
    height: 30px;
  }
  
  .App {
    font-family: Karla, sans-serif;
    text-align: center;
    background-color: #232323;
    color: white;
  }
  
  .app--body {
    padding-top: 50px;
  }
  
  .short--title {
    text-align: left;
    line-height: 0.8;
    font-size: 7vw;
    font-family: Karla, sans-serif;
    margin-top: 15px;
    margin-left: 3px;
  }

  .short--deatil-title {
    text-align: left;
    line-height: 0.8; /* Adjust line height for better readability */
    font-size: 4vh;
    font-family: Karla, sans-serif;
    margin-top: 15px;
    margin-left: 3px;
    font-weight: bold;
    word-wrap: break-word; /* Allows long words to be broken and wrap onto the next line */
    word-break: break-all; /* Breaks long words at arbitrary points to prevent overflow */
    white-space: normal; /* Allows text to wrap normally */
    overflow-wrap: break-word; /* Ensures text wraps within the container */
    cursor: default;
  }


  .short--detail-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5vh;
    margin-left: 3px;
    height: auto;
  }

  .short--user {
    text-align: left;
    /* margin-top: 1vh; */
    margin-left: 3px;
    margin-right: 2vh;
    font-size: 2.5vh;
    font-weight: lighter;
    flex-grow: 0;
  }

  .short--subscribe {
    text-align: left;
    /* margin-top: 1vh; */
    margin-left: 3px;
    font-size: 1.7vh;
    flex-grow: 0;
    background-color: rgba(255, 255, 255, 0.8); /* 20% transparent white background */

  }

  .short--subscribed {
    text-align: left;
    /* margin-top: 1vh; */
    margin-left: 3px;
    font-size: 1.7vh;
    flex-grow: 0;

    background-color: #6afcac !important;
    border-color: #6afcac !important;
    color: black;
  
  }


  .short--share {
    text-align: left;
    /* margin-top: 1vh; */
    margin-left: 3px;
    font-size: 1.7vh;
    flex-grow: 0;
    background-color: rgba(255, 255, 255, 0.8); /* 20% transparent white background */

  }

  .short--view-profile {
    text-align: left;
    /* margin-top: 1vh; */
    /* margin-left: 3px; */
    font-size: 1.7vh;
    flex-grow: 0;
    background-color: #6afcac !important;
    border-color: #6afcac !important;
  }

  .short--view-my-profile {
    text-align: left;
    /* margin-top: 1vh; */
    /* margin-left: 3px; */
    font-size: 1.7vh;
    flex-grow: 0;
    background-color: rgba(255, 255, 255, 0.8); /* 20% transparent white background */

  }

  .profile {
    margin-bottom: 200px;
    padding: 10px;
    padding-top: 50px;
    padding-left: 2rem;
    padding-right: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    display: flex;
    direction: row;
    width: 100%;


  }
  

  .profile--tabs > ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-size: 10rem;
    width: 100%;
    font-family: Karla, sans-serif;

  }

  .profile--tabs div {
    color: white !important;
    font-family: Karla, sans-serif;

  }
  
  .profile--tabs h1 {
    color: white !important;
    font-size: 3em;
    font-family: Karla, sans-serif;

  }

  
  /* Gmail login. Name of class, hope the name isn't dynamic. It looks static*/
  .S9gUrf-YoZ4jf {
    margin-top: 30 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .short--title {
    font-size: 20px; /* Set a maximum font size here */
  }
  
  /* 
  Computer layout (horizontal) 
  Display the thumbnails in a grid  
  */
  @media (min-width: 768px) {
    .app--body {
      /* Display the shorts in a grid. Min of 250px and 1 */
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      /* grid-template-columns: repeat(auto-fit, minmax(250px, 500px)); */
      grid-gap: 30px; /* Adjust gap between images as desired */
      max-width: 100vw; /* Adjust as needed for your layout */
      margin-left: 30px;
      margin-right: 30px;
      /* background: red; */
    }
  
    .short {
      /* Display the image and text within the container in vertical column */
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .short--video {
      /* width: 100%; */
      max-width: calc(100% - [padding value]); /* Subtract padding */
      aspect-ratio: 16/9 !important;
      height: auto !important;
      max-width: 100%; /* Fills width of parent `.short` element */
      aspect-ratio: 16/9;
      height: auto;
      /* background-color: green; */
      /* border: 20px solid green !important;  */
      position: relative;
      z-index: 10;
      margin-top: 15px; /* Changed from 10px to 15px */
    }
  
    .short--video > video {
      max-width: [parent_width] !important;
      /* width: 100vw !important; */
      aspect-ratio: 16/9 !important;
      height: auto !important;
    }
  }
   
  /* 
  Phone layout (vertical) 
  Display the thumbnails vertically  
  */
  @media (max-width: 768px) {
    .app--body {
      /* Display short's down the screen in a column */
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center; /* Vertically center items */
      align-items: center; /* Horizontally center items (optional) */
      /* background: yellow; */
    }
  
    .short {
      /* Display the image and text within the container in vertical column */
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .short--video {
      /* Make the video fill the entire wideth of screen. Keep the aspect ratio and height to match width */
      width: 100% !important;
      aspect-ratio: 16/9 !important;
      height: auto !important;
      /* background-color: red; */
      position: relative;
      z-index: 10;
      margin-top: 15px; /* Changed from 10px to 15px */
    }
  }
  
  /*
  Video player CSS
  Both vertially and horizontally 
  */
  .short--viewer {
    .app--body {
      /* do i nee this bit ? */
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center; /* Vertically center items */
      align-items: center; /* Horizontally center items (optional) */
    }
  
    /* Allign the video and title */
    .short {
      display: flex;
      flex-direction: column;
    }
  
    /* Large width  */
    @media (max-width: 1500px) {
      .short {
        max-width: calc(
          80vh * (16 / 9)
        ); /*Calculate the width based on the height and aspect ratio*/
        margin: 0 auto; /* Center the div horizontally */
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically center items */
        align-items: center; /* Horizontally center items */
        text-align: center; /* Center text inside the container */

      }
      

      .short--video {
        /* background: green; */
        /* max-width: 100vw !important; */
        /* width: [parent_width] !important; */

        width: 100% !important;
        height: auto; /* Let the height adjust automatically */
        max-height: 80vh !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px; /* Changed from 10px to 15px */
        /* background-color: blue; */
        /* border: 20px solid blue !important;  */
        position: relative;
        z-index: 10;


      }
  
      .short--video > video {
        max-width: [parent_width] !important;

      }

      .short--detail-container {
        max-width: calc(
          80vh * (16 / 9)
        ); /*Calculate the width based on the height and aspect ratio*/
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        width: 100%; /* Ensure the container takes the full width of the viewport */
        height: auto; /* Let the height adjust automatically */
        display: flex;
        justify-content: flex-start; /* Center content horizontally */
        align-items: flex-start; /* Center content vertically */
      }

      .short--deatil-title {
        max-width: calc(
          80vh * (16 / 9)
        ); /*Calculate the width based on the height and aspect ratio*/
        line-height: 0.8; 
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        width: 100%; /* Ensure the container takes the full width of the viewport */
        height: auto; /* Let the height adjust automatically */
        display: flex;
        justify-content: flex-start; /* Center content horizontally */
        align-items: flex-start; /* Center content vertically */
        word-wrap: break-word; /* Allows long words to be broken and wrap onto the next line */
        word-break: break-word; /* Breaks long words at arbitrary points to prevent overflow */
        white-space: normal; /* Allows text to wrap normally */
        overflow-wrap: break-word; /* Ensures text wraps within the container */
        cursor: none;
        padding: 0px; /* Adjust padding to control space above and below the text */


      }
    }

    
    /* Small width  */
    @media (min-width: 1500px) {
      .short--viewer {
        display: flex;
        direction: column;
        justify-content: center;
        /* background: red;
        border: red; */
        border-width: 3px;
      }
  
      .short {
        max-width: calc(
          90vh * (16 / 9)
        ); /*Calculate the width based on the height and aspect ratio */
        /* align-items: center; */
        margin: 0 auto; /* Center horizontally */
      }
  
      .short--video {
        width: 100% !important;
      }
  
      .short--video > video {
        max-height: 90vh !important; /* Important for aspect ratio preservation */
        aspect-ratio: 16/9 !important;
        max-width: calc(90vh * (16 / 9));
      }
  
      .short--video > .short--title {
        max-width: calc(
          90vh * (16 / 9)
        ); /*Calculate the width based on the height and aspect ratio*/
      }
    }
  }
  
  .upload--form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile {
    font-family: Karla, sans-serif;
  }
  
  .upload--form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .upload--title {
    font-family: Karla, sans-serif;
    /* margin-bottom: 20px; */
    height: 40px;
    border-radius: 5px;
    border: 1px solid white;
    padding: 5px;
  }
  
  label[for="title"] {
    text-align: left; /* Added for left alignment */
    font-family: Karla, sans-serif;
    font-weight: 400;
  }
  
  .upload--paywallStart {
    font-family: Karla, sans-serif;
    /* margin-bottom: 20px; */
    height: 40px;
    border-radius: 5px;
    border: 1px solid white;
    padding: 5px;
    font-family: "Inter", sans-serif;
  }
  label[for="paywallStart"] {
    text-align: left; /* Added for left alignment */
    font-family: Karla, sans-serif;
    font-weight: 400;
  }

  /* .upload--set-price {
    font-family: Karla, sans-serif;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid white;
    padding: 5px;
    font-family: "Inter", sans-serif;
  }
  label[for="upload--set-price"] {
    text-align: left; 
    font-family: Karla, sans-serif;
    font-weight: 400;
  }
   */
  .upload--chooseFile {
    padding: 10px;
    border-radius: 5px;
    color: black;
    background-color: white;
    border: none;
    cursor: pointer;
  }
  
  .upload--uploadButton {
     padding: 10px;
    border-radius: 5px;
    color: black;
    background-color: white;
    border: none;
    cursor: pointer;
  }

  .upload--thumbnail {
    width: 50% !important;
  }
  
  .paywall--image {
    max-width: calc(
      80vh * (16 / 9)
    ); 
    max-height: 80vh !important;
    position: relative; 
    width: 100% !important;

  }
  
  .short--paywall {
    width: 100%;
    height: 100%;
    /* max-width: calc(
      80vh * (16 / 9)
    );  */
    max-height: 80vh !important;
    position: relative;
    aspect-ratio: 16/9 !important;
    margin-top: 15px; /* Changed from 10px to 15px */
  }
  
  .paywall--overlay {
    z-index: 2; /* Optional: Set a base z-index for the image */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%; /* Fills the container height */
    background-color: white; /* Sets the background color to white */
    opacity: 0.9; /* Adjust opacity for desired translucency (0 for transparent, 1 for opaque) */
    display: flex;
    align-items: center; /* Vertically center elements */
    /* justify-content: center; Horizontally center elements */
    justify-content: space-around;
    z-index: 20; /* Higher than video but lower than header */
  }
  
  .paywall--subscribe-text,
  .paywall--back-text {
    /* CSS styles here */
    /* background-color:; */
    color: black;
    weight: bold;
  }
  
  .paywall--subscribe-button,
  .paywall--back-button {
    /* CSS styles here */
    /* background-color:; */
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: black;
    border: none;
    cursor: pointer;
    margin: 2px;
  }
  


  .ant-tabs-nav {
    display: flex;
    justify-content: center !important; /* Center the tabs */
    width: 100% !important; 
  }
  
  .ant-tabs-tab {
    color: #fff !important; /* White text color */
    font-size: 1rem !important;
  }


  .ant-tabs-tab:hover {
    color: #6afcac !important; /* Hover text color */
  }
  
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6afcac !important;
  }
  
  .ant-tabs-ink-bar {
    background-color: #6afcac !important; /* Active tab underline color */
  }

  .settings--item {
    width: 50%; /* Ensure it takes up 50% of the width of the screen */
    margin: 0 auto; /* Center the element horizontally */
    display: flex;
    justify-content: center;
    }
  
  .settings--item:hover {
    background-color: gray; /* Make it gray when hovered over */
  }

  
.ant-form-item-label {
  /* color: white !important; */
}

.ant-form-item-label > label {
  color: white !important;
}

.upload--form .ant-btn {
  background-color: #6afcac !important;
  border-color: #6afcac !important;
  color: black;
}

.ant-spin-dot-item {
  background-color: #6afcac !important; /* Set your desired color here */
}

.menu-container .ant-menu {
  background-color: #333; /* Black background */
  /* background-color: #232323; Black background */
  color: #fff; /* White text */
}

.menu-container .ant-menu-item {
  color: #fff; /* White text */
}

.menu-container .ant-menu-item:hover {
  background-color: #6afcac; /* Darker background on hover */
  color: white !important;
}

.menu-container .ant-menu-item-selected {
  background-color: #6afcac; 
  color: black;  
}

.myshorts--share-button {
  margin-right: auto; /* Push the button to the left */
}

.menu-group-label {
  /* font-weight: bold; */
  /* font-size: 16px;
*/  
  /* margin-top: 10px; */
  /* margin-bottom: 10px;  */
  color: #6afcac;
}


.ant-menu-item-group-title {
  background-color: #232323;
}

.ant-form-item-row {
  margin-top: 20px !important;
}

.custom-modal {
  z-index: 1050; /* Ensure this is higher than the menu's z-index */
  background-color: #232323;
  color: white;
}

/* Modal overlay to darken and blur the background */
.custom-modal .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.9) !important; /* More opaque black */
  backdrop-filter: blur(20px) !important; /* Increase blur effect */
}

/* Modal content styling */
.custom-modal .ant-modal-content {
  background-color: #232323 !important;
  color: black;
  border-radius: 8px; /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.5); /* More subtle border */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* More pronounced shadow */
}

/* Modal title styling */
.custom-modal .ant-modal-title {
  color: white !important;
  background-color: #232323 !important;
}

/* Modal body text color */
.custom-modal .ant-modal-body {
  color: #6afcac !important;
}

/* List item title color */
.custom-modal .ant-modal-body .ant-list-item-meta-title {
  color: #6afcac !important;
}

/* Ensure links within the list items also have the correct color */
.custom-modal .ant-modal-body .ant-list-item a {
  color: #6afcac !important;
  padding: 2px !important;
  padding-left: 4px !important;
}

.custom-modal .anticon {
  color: white !important;
}

.custom-modal .ant-modal-body .ant-list-items {
  background-color: #333 !important;
  margin: 4px !important;
}

/* Landing page styles that match the existing theme */
.landing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Karla, sans-serif;
  background-color: #232323;
  color: white;
}

.info-section {
  margin-top: 50px;
  padding: 30px;
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.info-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.info-card {
  flex: 1;
  min-width: 250px;
  padding: 25px;
  background-color: #232323;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.info-card:hover {
  transform: translateY(-5px);
  border-color: #6afcac;
}

.info-card h3 {
  margin-top: 0;
  color: #6afcac;
  font-size: 1.5rem;
}

.pricing-details {
  margin-top: 40px;
  padding: 25px;
  background-color: #232323;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.future-plans {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #444;
}

.future-plans li {
  margin-bottom: 8px;
}

.terms-section {
  margin-top: 40px;
}

.terms-header {
  cursor: pointer;
  padding: 15px;
  background-color: #333;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.terms-header:hover {
  background-color: #444;
}

.terms-content {
  padding: 20px;
  background-color: #232323;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none;
}

/* Style ordered list in terms - center alignment */
.terms-content ol {
  padding-left: 40px;  /* Keep this padding */
  margin-left: 0;      /* Keep this margin */
  text-align: center;  /* Change from left to center */
}

.terms-content li {
  margin-bottom: 10px;
  text-align: center;  /* Change from left to center */
  padding-left: 10px;  /* Keep this padding */
}

.terms-content p {
  text-align: center;  /* Change from left to center */
}

/* Make sure headings are also centered */
.terms-content h4 {
  color: #6afcac;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;  /* Add this */
}

/* Fix double emoji issue by removing the CSS content property */
.info-card p:before {
  content: none;
}

/* Make strong elements use the accent color */
.pricing-details strong {
  color: #6afcac;
  font-weight: 600;
}

/* Style headings consistently */
.info-section h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: center;
}

.pricing-details h3, 
.future-plans h4 {
  color: #6afcac;
  margin-bottom: 15px;
}

/* Add proper styling for the CTA buttons */
.cta-button {
  background-color: #6afcac;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
  font-weight: 500;
  display: inline-block;
  width: auto;
  text-align: center;
}

.cta-button:hover {
  background-color: #5ddea0;
}

/* Make sure Link components don't affect button styling */
a .cta-button {
  display: inline-block;
  width: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .info-cards {
    flex-direction: column;
  }
  
  .info-card {
    width: 100%;
  }
}